import React from "react";
import { Link, graphql } from "gatsby";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ReactMarkdown from "react-markdown";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import WeddingMap from "../components/weddingmap";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

import BoundsGeoJson from "../geo/bounds.json";

import "./things-to-do.scss";

const ThingToDo = ({ poi, bounds, onMouseEnter }) => {
  onMouseEnter.bind(poi);
  switch (poi.layout) {
    case "left":
    default:
      return (
        <Row
          className="thing-to-do"
          key={poi.name}
          onMouseEnter={e => onMouseEnter(e, poi, bounds)}
          onClick={e => onMouseEnter(e, poi, bounds)}
        >
          <Col className="col-12 col-md-8">
            <h5>{poi.name}</h5>
            <ReactMarkdown source={poi.description} />
          </Col>
          <Col className="col-12 col-md-4 photo-container">
            <div
              className="things-to-do-item-image"
              style={{ backgroundImage: "url(" + poi.photo + ")" }}
            />
          </Col>
        </Row>
      );
      break;

    case "right":
      return (
        <Row
          className="thing-to-do"
          key={poi.name}
          onMouseEnter={e => onMouseEnter(e, poi, bounds)}
          onClick={e => onMouseEnter(e, poi, bounds)}
        >
          <Col className="col-12 col-md-4 photo-container">
            <div
              className="things-to-do-item-image"
              style={{ backgroundImage: "url(" + poi.photo + ")" }}
            />
          </Col>
          <Col className="col-12 col-md-8">
            <h5>{poi.name}</h5>
            <ReactMarkdown source={poi.description} />
          </Col>
        </Row>
      );
      break;
  }
};

class ThingsToDoPage extends React.Component {
  constructor(props) {
    super(props);

    const data = this.props.data;
    const GeoJSON = require("ol/format/GeoJSON").default;
    const projectionGet = require("ol/proj").get;

    this.thingMouseEnterHandler = this.thingMouseEnterHandler.bind(this);

    this.bounds = {
      overview: null,
      westport: null,
      sanFrancisco: null,
      alongTheWay: null,
      mendocino: null
    };

    let _boundsFeatures = new GeoJSON().readFeatures(BoundsGeoJson, {
      dataProjection: projectionGet("EPSG:4326"),
      featureProjection: projectionGet("EPSG:3857")
    });

    _boundsFeatures.forEach(x => {
      switch (x.get("name")) {
        case "overview":
          this.bounds["overview"] = x;
          break;
        case "westport":
          this.bounds["westport"] = x;
          break;
        case "sf_bounds":
          this.bounds["sanFrancisco"] = x;
          break;
        case "alongtheway_bounds":
          this.bounds["alongTheWay"] = x;
          break;
        case "mendocino_bounds":
          this.bounds["mendocino"] = x;
          break;
      }
    });

    this.poiComponents = {
      westport: [],
      sanFrancisco: [],
      alongTheWay: [],
      mendocino: []
    };

    data.file.childPagesYaml.pois.map((poi, i) => {
      switch (poi.section) {
        case "Westport":
          this.poiComponents.westport.push(
            <ThingToDo
              key={i}
              poi={poi}
              bounds={this.bounds["overview"]}
              onMouseEnter={this.thingMouseEnterHandler}
            />
          );
          break;
        case "San Francisco":
          this.poiComponents.sanFrancisco.push(
            <ThingToDo
              key={i}
              poi={poi}
              bounds={this.bounds["sanFrancisco"]}
              onMouseEnter={this.thingMouseEnterHandler}
            />
          );
          break;
        case "Along the Way":
          this.poiComponents.alongTheWay.push(
            <ThingToDo
              key={i}
              poi={poi}
              bounds={this.bounds["alongTheWay"]}
              onMouseEnter={this.thingMouseEnterHandler}
            />
          );
          break;
        case "Mendocino":
          this.poiComponents.mendocino.push(
            <ThingToDo
              key={i}
              poi={poi}
              bounds={this.bounds["mendocino"]}
              onMouseEnter={this.thingMouseEnterHandler}
            />
          );
          break;
      }
    });

    this.state = {
      currentThing: null,
      currentBounds: this.bounds.overview
    };
  }

  thingMouseEnterHandler(e, thing, bounds) {
    this.setState({
      currentThing: thing,
      currentBounds: bounds
    });
  }

  render() {
    const data = this.props.data;
    return (
      <Layout page="things-to-do">
        <SEO title="Things To Do" />

        <Row>
          <Col className="col-md-1" />
          <Col className="content-column col-12 col-md-6">
            <PageHeader title={data.file.childPagesYaml.title} />

            <Row className="page-content section-header">
              <Col>
                <h3>Westport</h3>
                <p>Westport is the small town that Swizter Farm calls home.</p>
              </Col>
            </Row>
            <Row className="page-content">
              <Col>{this.poiComponents.westport}</Col>
            </Row>

            <Row className="page-content section-header">
              <Col>
                <h3>San Francisco</h3>
                <p>{data.file.childPagesYaml.sanFrancisco}</p>
              </Col>
            </Row>
            <Row className="page-content">
              <Col>{this.poiComponents.sanFrancisco}</Col>
            </Row>

            <Row className="page-content section-header">
              <Col>
                <h3>Along the Way</h3>
                <p>{data.file.childPagesYaml.alongTheWay}</p>
              </Col>
            </Row>

            <Row className="page-content">
              <Col>{this.poiComponents.alongTheWay}</Col>
            </Row>

            <Row className="page-content section-header">
              <Col>
                <h3>Mendocino</h3>
                <p>{data.file.childPagesYaml.mendocino}</p>
              </Col>
            </Row>

            <Row className="page-content">
              <Col>{this.poiComponents.mendocino}</Col>
            </Row>
          </Col>

          <Col className="map-column col-12 col-md-5">
            <WeddingMap
              mapid={"sf"}
              pois={data.file.childPagesYaml.pois}
              bounds={this.state.currentBounds}
              currentThing={this.state.currentThing}
            />
          </Col>
        </Row>

        <PageFooter />
      </Layout>
    );
  }
}

export const query = graphql`
  {
    file(relativePath: { eq: "pages/things-to-do.yml" }) {
      childPagesYaml {
        title
        description
        sanFrancisco
        alongTheWay
        mendocino
        pois {
          name
          visible
          layout
          section
          description
          link
          section
          photo
          location
        }
      }
    }
  }
`;

export default ThingsToDoPage;
